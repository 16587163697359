import { ErrorHandler, Injectable } from "@angular/core";
import { Tracker } from "../shared/utilities/tracker";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler{
  constructor(private logTracker: Tracker) {}
  handleError(error: Error): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if(chunkFailedMessage.test(error.message)) {
      // window.location.reload();
      this.logTracker.logerror('GlobalErrorHandler', 'handleError', 'ChunkLoadError',
        'Error=', error.message);
    }
  }
}
