<h1 mat-dialog-title>Delete Vehicle</h1>
<div mat-dialog-content>
    <p>Are you sure you want to delete this vehicle?</p>
</div>

    <div class="footer-buttons pt-2">
    <button mat-button mat-raised-button [mat-dialog-close]="true">Yes</button>
    <button mat-button mat-raised-button class="btn-next float-right" [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
    </div>
    
