<div class="save-exit">
    <div mat-dialog-content>
        <p class="mt-3">Are you sure you want to save & exit this quote?</p>    
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button id="ok" class="mt-3 btn btn-primary btn-next float-right" (click)="submit()">Yes</button>
        <button mat-raised-button id="cancel" class="mt-3 btn float-left" (click)="dialogRef.close(false)">
            Cancel
        </button>
    </div>
</div>

