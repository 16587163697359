<h1 mat-dialog-title>New Auto Quote</h1>
 <div mat-dialog-content>
    <form >
        <div class="form-group row">
          <label for="producerCode" class="col-sm-4 col-form-label">Producer Code</label>
          <div class="col-sm-8">
            <input type="text" class="form-control"  id="producerCode" value="">

          </div>
        </div>        
        <div class="form-group row">
          <label for="date" class="col-sm-4 col-form-label">Date</label>
          <div class="col-sm-8">
            <input type="date" class="form-control"  id="date" value="">
          </div>
        </div>
        <div class="form-group row">
          <label for="date" class="col-sm-4 col-form-label">Term</label>
          <div class="col-sm-8">
            <input type="text" class="form-control"  id="producerCode" value="">
          </div>
        </div>
        <div class="form-group row">
          <label for="date" class="col-sm-4 col-form-label">Producer Type</label>
          <div class="col-sm-8">
            <input type="text" class="form-control"  id="producerCode" value="">
          </div>
        </div>
      </form>
 </div>
 <div mat-dialog-actions>
    <button mat-button class="btn btn-md btn-secondary" style="margin-right: 22px;" (click)="close()">Close</button>
 <button mat-button class="btn btn-md theme-btn btn-primary"  (click)="close()">Submit</button>
 </div> 