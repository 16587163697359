<mat-toolbar class="mat-toolbar">
 <span>
   <a class="navbar-brand col-sm-3 p-0" >
  <img class="logo" src="assets/img/logo-bristol-west-new.svg" alt="Bristol West Logo"/>
</a>
</span>
<mat-list role="list" class="pl-5">
  <mat-list-item role="listitem"><a href="https://www.farmers.com/california-consumer-privacy/faq/#agents" target="_blank">Personal Information Use</a></mat-list-item>
  <mat-list-item role="listitem"><a href="javascript: void(0)" (click)="redirectForms()">Forms</a></mat-list-item>
  <mat-list-item role="listitem"><a href="javascript: void(0)" (click)="launchProgramGuide()">Program Guide</a></mat-list-item>
</mat-list>
 <span class="example-spacer"></span>
 
<!-- chat -->
<app-chat></app-chat>
</mat-toolbar>